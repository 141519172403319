import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Sunny Horizon Hotel
			</title>
			<meta name={"description"} content={"Відчуйте повний спектр розкоші та комфорту"} />
			<meta property={"og:title"} content={"Sunny Horizon Hotel"} />
			<meta property={"og:description"} content={"Відчуйте повний спектр розкоші та комфорту"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-2.jpg?v=2024-06-18T11:56:00.501Z"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
			<Override slot="link" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header12>
		<Section
			padding="140px 0 140px 0"
			sm-padding="40px 0 40px 0"
			background="linear-gradient(120deg,rgba(0,0,0,.2) 0%,rgba(16, 1, 1, 0.73) 100%),--color-dark url(https://uploads.quarkly.io/6671690efdcae00021e38570/images/1-1.jpg?v=2024-06-18T11:56:00.468Z) center/cover"
			color="--light"
			font="--base"
		>
			<Override slot="SectionContent" sm-align-items="center" />
			<Box width="100%">
				<Text
					as="h1"
					margin="0 0 96px 0"
					text-align="center"
					letter-spacing="20px"
					text-transform="uppercase"
					font="--headline3"
					lg-font="200 42px/1.2 --fontFamily-sans"
					lg-letter-spacing="20px"
				>
					Ваш оазис комфорту та розкоші
				</Text>
			</Box>
			<Box
				display="flex"
				margin="-16px -16px -16px -16px"
				flex-wrap="wrap"
				width="100%"
				justify-content="space-between"
			>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px" font="--lead" text-transform="uppercase">
							У готелі Sunny Horizon ми запрошуємо вас відчути неперевершену розкіш і комфорт.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					padding="16px 16px 16px 16px"
					width="25%"
					lg-width="50%"
					sm-width="100%"
				>
					<Box display="flex">
						<Text margin="0px">
							Наш заклад покликаний забезпечити вам незабутнє перебування, незалежно від того, приїхали ви сюди у справах чи на відпочинок.{" "}
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			/>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Text
					font="--headline3"
					lg-text-align="center"
					margin="16px 0px 24px 0px"
					color="--grey"
					md-text-align="left"
				>
					Завдяки нашому бездоганному сервісу та спокійній обстановці ви знайдете ідеальний відпочинок від суєти повсякденного життя.
				</Text>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11:56:00.467Z"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
						srcSet="https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-1.jpg?v=2024-06-18T11%3A56%3A00.467Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://uploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11:56:00.464Z"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-3.jpg?v=2024-06-18T11%3A56%3A00.464Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Готель Sunny Horizon - це рай для мандрівників, які шукають елегантності та відпочинку. Наші номери та люкси ретельно розроблені, щоб запропонувати вам спокійну та стильну відпочинок.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11:56:00.463Z"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						srcSet="https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/1-2.jpg?v=2024-06-18T11%3A56%3A00.463Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--grey"
					md-text-align="left"
				>
					Кожен простір обладнано сучасними зручностями та розкішними меблями, що гарантує вам максимальний комфорт. Наше прагнення до досконалості видно в кожній деталі, від вишуканого декору до першокласного обладнання.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			md-padding="80px 0 80px 0"
			background="--color-darkL1"
			color="--dark"
			quarkly-title="Reviews-14"
		>
			<Override slot="SectionContent" md-padding="0px 0 0px 0" grid-template-columns="repeat(3, 1fr)" grid-template-rows="auto" />
			<Text margin="0px 0px 15px 0px" color="--light" font="normal 600 46px/1.2 --fontFamily-sans" text-align="center">
				Чому обирають нас
			</Text>
			<Text margin="0px 0px 50px 0px" color="#b2bcc3" font="normal 300 22px/1.5 --fontFamily-sansHelvetica" text-align="center">
				Відчуйте втілення розкоші та комфорту в готелі Sunny Horizon.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				border-radius="8px"
				margin="0px 20px 25px 20px"
				display="grid"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 25px 0px"
				md-flex-direction="column"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							/>
							Розкішне розміщення: Наші номери та люкси спроектовані з урахуванням вашого комфорту, вирізняються розкішною постільною білизною, сучасними зручностями та елегантним декором.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Комплекс зручностей: від нашого розслаблюючого спа до нашого добре обладнаного фітнес-центру, ми пропонуємо низку зручностей, щоб покращити ваше перебування.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					border-color="#202757"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-border-width="0 0 1px 0"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 10px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							Універсальні приміщення для проведення заходів:  Незалежно від того, чи плануєте ви корпоративну зустріч чи особливе святкування, наші приміщення для проведення заходів можна адаптувати відповідно до ваших потреб.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					md-margin="0px 0px 35px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							/>
							Виняткове обслуговування: Наш відданий персонал прагне надати вам найвищий рівень обслуговування, гарантуючи, що ваше перебування буде бездоганним і приємним.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 26px 0px" md-margin="0px 0px 15px 0px">
						<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#b2bcc3">
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							/>
							Чудове розташування: наш готель розташований у зручному районі, що забезпечує легкий доступ до місцевих визначних пам'яток, магазинів і закладів харчування.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://uploads.quarkly.io/6671690efdcae00021e38570/images/2-3.jpg?v=2024-06-18T11:56:00.472Z"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-3.jpg?v=2024-06-18T11%3A56%3A00.472Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-3.jpg?v=2024-06-18T11%3A56%3A00.472Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-3.jpg?v=2024-06-18T11%3A56%3A00.472Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-3.jpg?v=2024-06-18T11%3A56%3A00.472Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-3.jpg?v=2024-06-18T11%3A56%3A00.472Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-3.jpg?v=2024-06-18T11%3A56%3A00.472Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6671690efdcae00021e38570/images/2-3.jpg?v=2024-06-18T11%3A56%3A00.472Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="center"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--lead"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Окрім чудових номерів, готель Sunny Horizon може похвалитися рядом зручностей, які задовольнять усі ваші потреби. Насолодіться освіженням у нашому блискучому басейні, розслабтеся в нашому ультрасучасному фітнес-центрі або побалуйте себе в нашому спокійному спа-центрі. У нашому готелі також є різноманітні приміщення для проведення заходів, які ідеально підходять для зустрічей, конференцій та особливих випадків. Незалежно від причини вашого візиту, ми тут, щоб зробити ваше перебування незабутнім.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer12>
			<Override slot="link3" />
			<Override slot="link1" />
		</Components.Footer12>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"658eb1148e2e8e00217540ff"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});